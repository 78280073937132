/**
 * Parses a date into a string usable with ICPSR APIs
 * @param date The date to parse into a string
 * @returns Returns a date in format mm/dd/yyyy
 */
export function dateToApiString(date: Date) {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

/**
 * Formats the date to a human-readable format
 * @param date - The date object to format
 * @returns A string in format Month Day, Year.
 */
export function formatDateToReadable(date: Date) {
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

/**
 * Formats the date to machine-readable format for HTML datetime attribute
 * @param date - The date object to format
 * @returns A string in format YYYY-MM-DD.
 */
export function formatDateToDatetime(date: Date) {
  return date.toISOString().split('T')[0];
}
