import React from 'react';
import {Spinner} from 'react-bootstrap';

/**
 * Renders a spinner to indicate loading. Will not block interaction with the page.
 * @param text Optional text to indicate what is loading
 */
export function LoadingSpinner(props: {text?: string}) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className="text-body mt-3 ps-3">{props.text}</p>
    </div>
  );
}
