import {SafeCustomHtml} from '@/components/SafeCustomHtml';
import {formatDateToReadable} from '@/lib/utils/date';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {NewsCategoriesBadges} from './NewsPostBadge';
import {getWordpressMedia} from '../../utils/api/wordpress';
import {useState, useEffect} from 'react';

type NewsPreviewProps = Readonly<{
  slug: string;
  title: string;
  content: string;
  createdDate: Date;
  newsCategories: number[];
  featuredMediaId?: number;
}>;

/**
 * Renders a preview card for a news post
 * @param slug - The slug leading to the full news page
 * @param title - The title of this piece of news
 * @param createdDate - The date this news was posted
 */
export function NewsPreview(props: NewsPreviewProps) {
  const router = useRouter();
  const {archive} = router.query;
  const contentConfig = {USE_PROFILES: {html: false}}; //removes all html tags
  const newsPageHref = {
    pathname: `/${archive}/news/${props.slug}`,
  };

  const [newsImage, setNewsImage] = useState<string | null>(null);
  const [newsImageAltText, setNewsImageAltText] = useState<string>('');

  useEffect(() => {
    if (props.featuredMediaId) {
      getWordpressMedia('shared', props.featuredMediaId)
        .then(response => {
          if (response?.data) {
            const {source_url, alt_text} = response.data;
            if (source_url) setNewsImage(source_url);
            if (alt_text) setNewsImageAltText(alt_text);
          }
        })
        .catch(error => {
          console.error('Error fetching featured media:', error);
        });
    }
  }, [props.featuredMediaId]);

  return (
    <div className="news-post stack stack-2">
      <NewsCategoriesBadges categoryIds={props.newsCategories} />
      <div className="news-post-image">
        {newsImage && (
          <img
            className="img-fluid"
            src={newsImage}
            alt={newsImageAltText}
            width={128}
            height={128}
          />
        )}
      </div>
      <div className="news-post-title">
        <Link href={newsPageHref}>{props.title}</Link>
      </div>
      <div className="news-post-summary">
        <SafeCustomHtml html={props.content} config={contentConfig} />
      </div>
      <div className="news-post-date">
        {formatDateToReadable(props.createdDate)}
      </div>
    </div>
  );
}
