import {GetServerSideProps} from 'next';
import getNextConfig from 'next/config';
import {sanitizeForSerialization} from '@/lib/utils/http';
import {
  loadArchiveCategories,
  loadArchivePostCategories,
  loadNewsCategories,
  loadSidebar,
  loadTags,
  loadWordpressFooter,
  loadWordpressNavigation,
  loadWordpressPage,
} from './loaders';
import {convertStringToKebabCase, normalize} from '@/lib/utils/strings';
import getConfig from '@/lib/config/config';
import {Result} from '@/lib/utils/result';
import {CategoryPostType} from '../types/category';
import {WordpressNavigation} from '../types/navigation';
import {WordpressFooter} from '../types/footer';
import {SidebarContent} from './transform/sidebar';

type StaticProps = ReturnType<GetServerSideProps>;

const NOT_FOUND = {notFound: true} as const;

async function getSidebar(
  rawSlug: string,
  archive: string
): Promise<SidebarContent[]> {
  if (rawSlug !== undefined) {
    const kebabSlug = convertStringToKebabCase(rawSlug);
    const result = await loadSidebar(kebabSlug, archive);
    if (result.ok === true) {
      return result.data;
    } else {
      return result.error;
    }
  } else {
    return [] as SidebarContent[];
  }
}

/**
 * Fetches the props for a given wordpress page from the appropriate endpoints
 * @param ctx - The NextPageContext of the getServerSideProps, containing query information
 * @returns An object containing props on success; on failure, a {notFound: true} object
 */
export async function getWordpressPageProps(
  archive: string,
  slug: string
): Promise<StaticProps> {
  if (!canCallWordpress(archive)) return NOT_FOUND;
  const promises = [
    loadWordpressPage(archive, slug),
    loadWordpressLayout(archive),
  ] as const;
  const [page, layout] = await Promise.all(promises);
  if (!page) return NOT_FOUND;
  const sidebar = await getSidebar(
    page.acf?.section_navigation_selector?.label,
    archive
  );

  return {
    props: sanitizeForSerialization({
      page,
      ...layout,
      sidebar,
    }),
  };
}

/**
 * Checks if Wordpress endpoints can be called for the given archive
 * @param archive - The archive to fetch from
 * @returns True if Wordpress can be called. False otherwise
 */
function canCallWordpress(archive: string) {
  if (!isWordpressEnabled() || !archive) return false;
  if (!isArchiveAllowed(archive)) return false;
  return true;
}

/**
 * Handles fetching the props for the news index page
 * @param archive - The archive to load props for
 * @param postType - The specific type of post to fetch
 * @returns A serverside props object containing the wordpress layout and various categories for filtering
 */
export async function getNewsIndexPageProps(
  archive: string,
  postType: CategoryPostType
): Promise<{}> {
  if (!canCallWordpress(archive)) return NOT_FOUND;

  const promises = [
    loadNewsCategories(),
    loadArchiveCategories(postType),
    loadTags(postType),
    loadWordpressLayout(archive),
  ] as const;
  const [newsCategories, archiveCategories, tags, layout] = await Promise.all(
    promises
  );

  return {
    props: sanitizeForSerialization({
      newsCategories: newsCategories.ok ? newsCategories.data : [],
      archiveCategories: archiveCategories.ok ? archiveCategories.data : [],
      tags: tags.ok ? tags.data : [],
      ...layout,
    }),
  };
}

/**
 * @param archive - archive to load posts from
 * @param category - post type to grab
 * @returns serverside props object for a generic post page for
 * one post type in an archive
 */
export async function getPostIndexPageProps(archive: string): Promise<{}> {
  if (!canCallWordpress(archive)) return {};
  const promises = [
    loadArchivePostCategories(archive),
    loadWordpressLayout(archive),
  ] as const;

  const [archiveCategories, layout] = await Promise.all(promises);
  return {
    props: sanitizeForSerialization({
      archiveCategories: archiveCategories.ok ? archiveCategories.data : [],
      ...layout,
    }),
  };
}

/**
 * Fetches the archive-specific information for a page's layout
 * @param archive - The archive to load layout information for
 * @returns An object containing the navigation and footer information
 */
export async function loadWordpressLayout(archive: string) {
  if (!canCallWordpress(archive)) return {};

  const promises = [
    loadWordpressNavigation(archive),
    loadWordpressFooter(archive),
  ] as const;
  const [navigation, footer] = await Promise.all(promises);

  return {
    navigation,
    footer,
  };
}

type WordpressPostPageProps<PostPage> =
  | {
      props: {
        navigation: WordpressNavigation;
        footer: WordpressFooter;
        page: PostPage;
      };
    }
  | {notFound: true};

/**
 * Loads the props for a Wordpress Post page. Individual post
 * page data is loaded through a fetcher function and then validated
 * @param archive - The current archive as given by the URL parameter
 * @param fetcher - A callback function that loads the page data and returns it as a result
 * @returns The Wordpress page props, if the fetcher was successful; a Not Found response otherwise
 */
export async function getWordpressPostPageProps<T>(
  archive: string,
  fetcher: () => Promise<Result<T>>
): Promise<WordpressPostPageProps<T>> {
  const promises = [fetcher(), loadWordpressLayout(archive)] as const;

  const [postPageResult, {navigation, footer}] = await Promise.all(promises);

  if (!postPageResult.ok) return {notFound: true};

  return {props: {page: postPageResult.data, navigation, footer}};
}

/**
 * Gets the static wordpress paths for creating pages in the build step
 */
export function getStaticWordpressPaths() {
  if (!isWordpressEnabled()) return {paths: [], fallback: false};

  const paths = [{params: {archive: 'nanda'}}];
  // Fallback=blocking allows for paths not included here to still be called
  // and generated at runtime, but prevents undefineds from coming as props
  const fallback = 'blocking';
  return {paths, fallback};
}

/**
 * Checks if wordpress is currently enabled
 */
function isWordpressEnabled() {
  const config = getNextConfig();
  if (config.publicRuntimeConfig.flagEnableWordpress === true) {
    return true;
  }
  return false;
}

const INVALID_ARCHIVES = ['_next'];

/**
 * Checks if an archive is allowed in a given environment. Allows for test archives
 * in development while disallowing them in production.
 * @param archive - The archive to check
 * @returns Boolean. True if allowed, false if not
 */
function isArchiveAllowed(archive: string) {
  if (INVALID_ARCHIVES.includes(archive)) return false;

  const config = getConfig();
  const {nodeEnv, deploymentEnv} = config.site;

  const developmentEnvironments = ['local', 'dev'];
  const testArchives = ['test'];

  const isTestArchive = testArchives.includes(normalize(archive));

  if (!isTestArchive) return true;

  const isDevelopmentEnvironment = nodeEnv === 'development';
  const isDevelopmentDeployment =
    developmentEnvironments.includes(deploymentEnv);

  if (isDevelopmentEnvironment || isDevelopmentDeployment) return true;

  return false;
}

export const __testing__ = {canCallWordpress, isArchiveAllowed};
