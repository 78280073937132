import React, {useEffect, useState} from 'react';
import {fetchNewsCategory} from '../../utils/api/wordpress';

type NewsPostBadgeProps = Readonly<{
  category?: string;
}>;

/**
 * Renders a badge for news posts
 * @param category The category to display in the badge
 * @returns A Badge
 */
export function NewsPostBadge(props: NewsPostBadgeProps) {
  return (
    <span className="badge badge-secondary">
      {props.category ? props.category : 'Article'}
    </span>
  );
}

/**
 * Renders a series of badges with dynamic names of news post categories
 * @param categoryIds Array of news category IDs
 * @returns A flex wrapped series of badges
 */
export function NewsCategoriesBadges({categoryIds}) {
  const [categoryNames, setCategoryNames] = useState([]);

  useEffect(() => {
    const fetchAllCategoryNames = async () => {
      const fetchPromises = categoryIds.map(fetchNewsCategory);

      try {
        const namesArrays = await Promise.all(fetchPromises);
        const allNames = namesArrays.flat();
        setCategoryNames(allNames);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };

    fetchAllCategoryNames();
  }, [categoryIds]);

  return (
    <div className="d-flex flex-wrap gap-2">
      {categoryIds.length > 0 &&
        categoryNames.map(categoryName => (
          <NewsPostBadge key={categoryName} category={categoryName} />
        ))}
    </div>
  );
}
